import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { QuestionnaireEditor } from "./components/Questionaire/QuestionnaireEditor";
import { QuestionnairePreview } from "./components/QuesitonnairePreview/QuestionnairePreview";
import { HomePage } from "./components/Home/HomePage";
import { Questionnaire } from "./components/Questionaire/Questionnaire";
import useAsyncFn from "./utils/useAsyncFn";
import { me } from "./http/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UtilsPage } from "./components/Utils/UtilsPage";

const AuthCheck = ({ children, auth }: PropsWithChildren<{ auth: boolean }>) => {
  const token = localStorage.getItem("__token");
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, data, exec } = useAsyncFn(me);

  const logout = useCallback(() => {
    localStorage.removeItem("__token");
    window.location.reload();
  }, []);

  useEffect(() => {
    if (!token && auth && location.pathname !== "/") {
      navigate("/");
    } else if (token) {
      exec(token);
    }
  }, [token, auth, location.pathname, navigate, exec]);

  useEffect(() => {
    if (data?.ok && !auth) {
      navigate("/questionnaire");
    } else if (data?.ok === false && auth) {
      navigate("/");
    }
  }, [data, auth, navigate]);

  if (loading)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Gathering user data...
        </div>
      </div>
    );

  if (!token && auth)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Redirecting to login page...
        </div>
      </div>
    );

  if (token && data?.ok && !auth)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Redirecting to questionnaire page...
        </div>
      </div>
    );

  return (
    <div className={"relative"}>
      {children}
      {auth && (
        <button className={"fixed bottom-4 left-4 w-32 py-1 bg-black text-white rounded-xl shadow"} onClick={logout}>
          Logout
        </button>
      )}
    </div>
  );
};

function App() {
  return (
    <div className={"w-screen h-screen bg-gray-50  overflow-y-auto p-1 flex flex-col gap-4"} id={"app"}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <AuthCheck auth={false}>
                <HomePage />
              </AuthCheck>
            }
          />
          <Route
            path='/questionnaire'
            element={
              <AuthCheck auth>
                {" "}
                <Questionnaire />{" "}
              </AuthCheck>
            }
          />
          <Route
            path='/questionnaire/:id'
            element={
              <AuthCheck auth>
                <QuestionnaireEditor />
              </AuthCheck>
            }
          />
          <Route
            path='/questionnaire/:id/preview'
            element={
              <AuthCheck auth>
                <QuestionnairePreview />
              </AuthCheck>
            }
          />
          <Route
            path="/utils"
            element={
              <AuthCheck auth>
                <UtilsPage />
              </AuthCheck>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
