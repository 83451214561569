import Papa from "papaparse";
import {
  InputType,
  Question,
  Questionnaire,
  Questionnaire as Q,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValidityAction,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
  QuestionVisibility,
  RuleGroupEntity,
  RuleGroupKind,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Val } from "@Savus-Inc/dsl/dist/types";
import {
  addQuestionRuleGroup,
  createQuestionnaire,
  editQuestion,
  questionnaireBulkInsert,
  removeQuestion,
  removeQuestionRuleGroup,
} from "../../http/questionnaire";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";
import { ruleGroupToString, stringToOp, stringToRuleGroup } from "@Savus-Inc/dsl/dist/utils/transformer";
import { AccordionItem } from "../util/Accourdion";
import classNames from "classnames";
import ToggleAcceptDecline from "../util/Toggle";
import { unpack } from "../../utils/unpack";
import { useAppState } from "../../state/State";
import { toast } from "react-toastify";

function getInputTypeAndQuestionKind(item: any): Pick<Question, "kind" | "inputType"> {
  switch (item.fieldInputType) {
    case "header":
      return {
        inputType: InputType.Header,
        kind: Val.String,
      };
    case "selectAutocomplete":
    case "selectMultiple":
    case "select":
    case InputType.Select:
      return {
        inputType: InputType.Select,
        kind: Val.String,
      };
    case "buttonGroupRadio":
    case InputType.RadioGroup:
    case InputType.RadioGroup.toLowerCase():
      return {
        inputType: InputType.RadioGroup,
        kind: Val.String,
      };
    case InputType.YesNo.toLowerCase():
    case InputType.YesNo:
      return {
        inputType: InputType.YesNo,
        kind: Val.String,
      };
    case "buttonGroupCheckbox":
    case InputType.Checkbox:
    case InputType.Checkbox.toLowerCase():
      return {
        inputType: InputType.Checkbox,
        kind: Val.String,
      };
    case "textarea":
    case InputType.RichText:
      return {
        inputType: InputType.RichText,
        kind: Val.String,
      };
    case "number":
      return {
        inputType: undefined,
        kind: Val.Number,
      };
    case "date":
      return {
        inputType: undefined,
        kind: Val.Date,
      };

    default: {
      return {
        inputType: null as never,
        kind: item.questionKind || Val.String,
      };
    }
  }
}

function getQuestionFormat(item: string) {
  if (!item) return undefined;
  const val = Object.values(QuestionValueFormatting).find(v => item.toLowerCase().includes(v.toLowerCase()));

  return val;
}
const ALL_CARRIER_REFS = [
  "ClassCodeChubb",
  "ClassCodeCna",
  "ClassCodeNationWide",
  "ClassCodeLibertyMutual",
  "ClassCodeTravelers",
];
function getCarrierRefValue(carrier: string): string {
  switch (carrier.toLowerCase()) {
    case "chubb":
      return "ClassCodeChubb";
    case "cna":
      return "ClassCodeCna";
    case "nationwide":
      return "ClassCodeNationWide";
    case "liberty mutual":
      return "ClassCodeLibertyMutual";
    case "travelers":
      return "ClassCodeTravelers";
    default:
      throw `carrier not found for ${carrier}`;
  }
}

function processVisibilityGroup(gr: string, fieldMap: { [p: string]: string }, i: number) {
  const [group, action] = gr.split(" -- ").map((v: string) => v.trim());

  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
  return {
    ...ruleGroup,
    name: gr,
    kind: RuleGroupKind.Visibility,
    partOrder: i,
    actionKind:
      action.toLowerCase() === "show"
        ? QuestionVisibility.Show
        : action.toLowerCase() === "readonly"
        ? QuestionVisibility.ReadOnly
        : QuestionVisibility.Hidden,
    id: v4(),
  };
}

const UploadCSV: React.FC = () => {
  const navigate = useNavigate();

  const {
    state: { carriers },
  } = useAppState();

  const [consolidations, setConsolidations] = useState<
    {
      question: QuestionnaireQuestionEntity;
      label: string;
      diffs: { part: string; message: { message: string; from: any; to: any }; accepted: boolean }[];
      order: number;
      page: number;
      pageTitle: string;
      id: string | undefined;
      accepted: boolean;
    }[]
  >([]);

  const [term, setTerm] = useState<string>("");
  const [pendingPayload, setPendingPayload] = useState<Questionnaire>();

  const { item: currentQuestionnaire, questions: currentQuestions } = useBuilderNGN();

  const product = useMemo(() => {
    if (!currentQuestionnaire) return;

    if (currentQuestionnaire.lineOfBusiness?.shortName === "GLI") {
      return "GL";
    }

    return currentQuestionnaire.lineOfBusiness?.shortName;
  }, [currentQuestionnaire]);

  const [message, setMessage] = useReducer((_: string, v: string) => v, "");
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (currentQuestionnaire && !currentQuestionnaire?.lineOfBusinessId) {
      alert("Please select a product for questionnaire before the update.");
      window.location.reload();
      return;
    }

    setMessage("Preparing file...");

    if (file?.type?.toLowerCase()?.includes("json")) {
      const q: Questionnaire = JSON.parse(await file?.text());
      setMessage("Creating questionnaire...");

      const base = {
        id: q.id,
        title: q.title,
        name: q.name,
        subText: q.subText,
      };
      createQuestionnaire(base).then(async () => {
        setMessage("inserting data questionnaire (do not reload or leave your page)...");

        for (const group of q.groups) {
          setMessage(`Updating page (P-${group.partOrder}) ${group.title}`);
          const groupData = {
            ...group,
            questions: group.questions.map(q => ({
              ...q,
              ruleGroups: q.ruleGroups.map((rg, i) => ({ ...rg, partOrder: i })),
            })),
          };

          const batchedQuestions = [];
          const batchSize = 100;

          for (let i = 0; i < groupData.questions.length; i += batchSize) {
            batchedQuestions.push(groupData.questions.slice(i, i + batchSize));
          }

          for (let i = 0; i < batchedQuestions.length; i++) {
            setMessage(
              `Updating batch ${i + 1} of ${batchedQuestions.length} for page (P-${group.partOrder}) ${group.title}`,
            );

            await questionnaireBulkInsert(base.id, {
              ...base,
              groups: [
                {
                  ...groupData,
                  questions: batchedQuestions[i],
                },
              ],
            } as Q);
          }
        }
        navigate(base.id);
      });

      return;
    }

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async results => {
          const isInQuestionnaireContext = !!currentQuestionnaire;

          const fieldMap = Object.fromEntries(
            (isInQuestionnaireContext ? currentQuestions : []).map(v => [v.question?.externalId, v.id]),
          );

          setMessage("Converting data...");
          const csvData = results.data as any[];

          const questions: Record<string, QuestionnaireQuestionEntity> = {};
          const groups: Record<string, QuestionnaireQuestionGroupEntity> = {};
          const sections: Record<string, QuestionnaireGroupSection & { groupId: string }> = {};

          const deleted = new Set();
          const edited = new Set();
          const logs = [];

          let base: any;
          for (const item of csvData) {
            if (
              !item.questionId ||
              (!!currentQuestionnaire && !item.product?.toLowerCase()?.includes(product?.toLowerCase()))
            )
              continue;

            if (!base)
              base = {
                title: `Questionnaire ${item?.product}`,
                name: `Questionnaire ${item?.product}`,
                subText: csvData[0]?.subText,
                id: isInQuestionnaireContext ? currentQuestionnaire.id : v4(),
              };

            if (!groups[item.pageId]) {
              const page = currentQuestionnaire?.groups.find(g => {
                return g.partOrder === +(item.pageOrder as string);
              }) as QuestionnaireQuestionGroupEntity | undefined;

              groups[item.pageId] = {
                questionnaireId: base.id,
                ruleGroups: [],
                id: page?.id || v4(),
                title: page?.title || item.pageTitle,
                subText: page?.subText || item.pageSubText,
                partOrder: item.pageOrder,
                questions: [],
                sections: [],
                displaySections: page?.displaySections || item.pageDisplay,
              };
            }

            const sectionId =
              item.sectionId && !item.questionContainerId ? `${item.pageId}-${item.sectionId}` : undefined;

            if (sectionId && !sections[sectionId]) {
              const section = currentQuestionnaire?.groups
                .flatMap(g => g.sections)
                .find(s => s?.title === item.sectionTitle);

              sections[sectionId] = {
                display: item.sectionDisplay,
                id: section?.id || v4(),
                partOrder: item.sectionOrder,
                questions: [item.questionId],
                groupId: item.pageId,
                title: item.sectionTitle,
              };
            } else if (sectionId && !sections[sectionId].questions.includes(item.questionId)) {
              sections[sectionId].questions.push(item.questionId);
            }

            if (fieldMap[item.questionId] && !questions[item.questionId] && !deleted.has(item.questionId)) {
              const question = currentQuestions.find(q => q.question?.externalId === item.questionId);
              if (!question) {
                console.log("Should never happen...", item.questionId);
                continue;
              }

              if (!!item.delete) {
                if (item.questionId === "Q-63") {
                  deleted.add(item.questionId);
                  continue;
                }

                deleted.add(item.questionId);
                logs.push(`Deleted:  ${item.questionId}`);
                await removeQuestion(base.id, groups[item.pageId].id, question.id)
                  .catch(console.error)
                  .then(() => toast.info(`Deleted question ${item.label}`));
                continue;
              }

              if (!question.showForCarriers) {
                // console.log(question);
                continue;
              }

              const questionPage = currentQuestionnaire.groups.find(g => question.groupId === g.id);
              const itemPage = currentQuestionnaire.groups.find(g => g.partOrder == item.pageOrder);

              if (questionPage?.id !== itemPage?.id && !edited.has(item.questionId)) {
                logs.push(
                  `Updated question page ${item.questionId} from ${questionPage?.partOrder} to ${itemPage?.partOrder}`,
                );
                edited.add(item.questionId);

                question.groupId = itemPage?.id as string;
                await editQuestion(base.id, groups[item.pageId].id, question.id, {
                  groupId: itemPage?.id,
                  partOrder: +item.questionOrder,
                })
                  .catch(console.error)
                  .then(() =>
                    toast.info(
                      `Updated question page ${item.questionId} from ${questionPage?.partOrder} to ${itemPage?.partOrder}`,
                    ),
                  );
              }

              if (!(question.showForCarriers || []).some(v => v.toLowerCase().includes(item.carrier.toLowerCase()))) {
                // console.log("Carrier state", question.showForCarriers.join(), item.carrier);

                question.showForCarriers.push(
                  ...carriers.filter(c => item?.carrier?.toLowerCase().includes(c.name.toLowerCase())).map(c => c.name),
                );
                question.showForCarriers = Array.from(new Set(question.showForCarriers));

                await editQuestion(base.id, groups[item.pageId].id, question.id, {
                  showForCarriers: question.showForCarriers.join(",") as never,
                  partOrder: +item.questionOrder,
                }).catch(console.error);
              }

              if (!!item.visibility) {
                const myCarrierRef = getCarrierRefValue(item.carrier);
                const visibility =
                  myCarrierRef === "ClassCodeNationWide"
                    ? item.visibility.replaceAll("ClassCodeNationwide", "ClassCodeNationWide")
                    : item.visibility;

                let visibilityHasRef = visibility.includes(myCarrierRef);

                if (visibilityHasRef) {
                  const ruleGroups = question.ruleGroups.filter(v => v.kind === RuleGroupKind.Visibility);

                  if (myCarrierRef === "ClassCodeNationWide") {
                    let bad = ruleGroups.filter(v => ruleGroupToString(v).includes("ClassCodeNationwide"));
                    await Promise.all(
                      bad.map(async v => {
                        await removeQuestionRuleGroup(
                          base.id,
                          question.groupId,
                          question.id,
                          v.id,
                          RuleGroupKind.Visibility,
                        );
                      }),
                    );
                  }

                  if (!ruleGroups.some(v => ruleGroupToString(v).includes(myCarrierRef))) {
                    const groups = visibility
                      .split(";")
                      .map((v: string) => v.trim())
                      .map((gr: string, i: number) => {
                        return processVisibilityGroup(
                          gr.replaceAll("ClassCodeNationwide", "ClassCodeNationWide"),
                          fieldMap,
                          i,
                        );
                      });

                    question.ruleGroups.push(...groups);

                    let i = question.ruleGroups.length;
                    for (const rg of groups) {
                      rg.name = `Visibility ${myCarrierRef}`;
                      rg.partOrder = i;
                      i++;
                      // console.log(` question ${question.question?.externalId}`, rg);
                      await addQuestionRuleGroup(base.id, question.groupId, question.id, rg);
                      toast.info(`Added visibility rule to question ${question.question?.externalId}`);
                    }
                  }
                }
              }

              continue;
            }

            let newQuestion = !questions[item.questionId];
            if (newQuestion) {
              let qId: string;
              if (fieldMap[item.questionId]) {
                qId = fieldMap[item.questionId];
              } else {
                qId = v4();
                fieldMap[item.questionId] = qId;
              }

              const question: QuestionnaireQuestionEntity = {
                parentId: item.questionContainerId || undefined,
                sectionId: sectionId ? sections[sectionId]?.id : undefined,
                id: qId,
                partOrder: +item.questionOrder,
                ruleGroups: [],
                questionnaireId: base.id,
                questionId: qId,
                showForCarriers: carriers
                  .filter(c => item?.carrier?.toLowerCase().includes(c.name.toLowerCase()))
                  .map(c => c.name),
                question: {
                  id: qId,
                  externalId: item.questionId,
                  autocompleteIntegration: item.autocompleteIntegration
                    ? item.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues ||
                      item.autocompleteIntegration === "GoogleApi"
                      ? QuestionValueAutocompleteIntegration.OwnValues
                      : QuestionValueAutocompleteIntegration.GoogleLocation
                    : null,
                  values: [],
                  format: getQuestionFormat(item.questionFormat),
                  label: item.fieldLabel,
                  placeholder: item.fieldPlaceholder,
                  subText: item.fieldSubText,
                  tooltip: item.fieldTooltip,
                  subQuestions: item.subQuestionDisplay || undefined,
                  maxAnswers: item.maxAnswers || undefined,
                  minAnswers: item.minAnswers || undefined,
                  answerDisplay: item.answerDisplay || undefined,
                  multipleAnswers: item.fieldMultipleAnswers?.toLowerCase() === "true",
                  ...getInputTypeAndQuestionKind(item),
                } as Question,
                groupId: item.pageId,
              } as unknown as QuestionnaireQuestionEntity;

              questions[item.questionId] = question;
            } else if (
              !questions[item.questionId].showForCarriers.some(
                v => v.toLowerCase().trim() === item.carrier.toLowerCase().trim(),
              )
            ) {
              questions[item.questionId].showForCarriers.push(
                ...carriers.filter(c => item?.carrier?.toLowerCase().includes(c.name.toLowerCase())).map(c => c.name),
              );
            }

            if (
              item.autocompleteIntegration &&
              item.answerLabel &&
              !questions[item.questionId]?.question?.values.some(v => v.label === item.answerLabel)
            ) {
              const question = questions[item.questionId].question as Question;

              const visibility: RuleGroupEntity[] = [];
              if (item.valueVisibility?.trim()) {
                const groups = item.valueVisibility
                  .split(";")
                  .map((v: string) => v.trim())
                  .map((gr: string, i: number) => {
                    return processVisibilityGroup(
                      gr.replaceAll("ClassCodeNationwide", "ClassCodeNationWide"),
                      fieldMap,
                      i,
                    );
                  });

                visibility.push(...groups);
              }

              question.values.push({
                id: item.ValueId || item.valueId,
                partOrder: Number.isNaN(+item.answerOrder) ? 0 : +item.answerOrder,
                value: { value: item.answerLabel } as never,
                questionId: questions[item.questionId].id,
                label: item.answerLabel,
                visibility,
              });
            }

            if (newQuestion && item.validation?.trim()) {
              const groups = item.validation
                .split(";")
                .filter((v: string) => !!v.trim())
                .map((v: string) => v.trim())
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split(" -- ").map((v: string) => v.trim());

                  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                  return {
                    ...ruleGroup,
                    name: gr,
                    kind: RuleGroupKind.Validation,
                    partOrder: i,
                    actionKind: {
                      message: action.replace(/Error|Warning|\(|\)/g, ""),
                      alertLevel: action.startsWith("Error")
                        ? ValidationAlertLevel.Error
                        : ValidationAlertLevel.Warning,
                    } as QuestionValidityAction,
                  };
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            } else if (newQuestion && item.Required) {
              const ruleGroup = stringToRuleGroup(`Unanswered($${fieldMap[item.questionId]}, True)`)[0];
              questions[item.questionId].ruleGroups.push({
                ...ruleGroup,
                id: v4(),
                name: `Unanswered($${questions[item.questionId].id}, True)`,
                kind: RuleGroupKind.Validation,
                partOrder: questions[item.questionId].ruleGroups.length,
                actionKind: {
                  message: "Required",
                  alertLevel: ValidationAlertLevel.Error,
                } as QuestionValidityAction,
              } as never);
            }
            if (newQuestion && item.visibility?.trim()) {
              const groups = item.visibility
                .split(";")
                .map((v: string) => v.trim())
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split(" -- ").map((v: string) => v.trim());

                  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                  return {
                    ...ruleGroup,
                    name: gr,
                    kind: RuleGroupKind.Visibility,
                    partOrder: i,
                    actionKind:
                      action.toLowerCase() === "show"
                        ? QuestionVisibility.Show
                        : action.toLowerCase() === "readonly"
                        ? QuestionVisibility.ReadOnly
                        : QuestionVisibility.Hidden,
                    id: v4(),
                  };
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            }

            if (newQuestion && item.prefill?.trim()) {
              const groups = item.prefill
                .split(";")
                .map((v: string) => v.trim())
                .map((gr: string, i: number) => {
                  const [group, action] = gr.split(" -- ").map((v: string) => v.trim());

                  try {
                    const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
                    return {
                      ...ruleGroup,
                      name: gr,
                      partOrder: i,
                      kind: RuleGroupKind.PreFill,
                      actionKind: stringToOp(action, fieldMap),
                      id: v4(),
                    };
                  } catch (e) {
                    // console.log(action, item.questionId);
                    throw e;
                  }
                });

              if (groups && groups.length > 0) {
                questions[item.questionId].ruleGroups.push(...groups);
              }
            }
          }

          console.log(logs.join("\n\n"));
          Object.values(questions)
            .sort((a, b) => a.partOrder - b.partOrder)
            .forEach(q => {
              q.question?.values.sort((a, b) => a.partOrder - b.partOrder);
              q.parentId = q.parentId ? questions[q.parentId]?.id || fieldMap[q.parentId]?.id : undefined;
              const groupId = q.groupId;
              q.groupId = groups[q.groupId].id;
              groups[groupId].questions.push(q);
            });

          Object.values(sections)
            .sort((a, b) => a.partOrder - b.partOrder)
            .forEach(s => {
              s.questions = s.questions.map(k => questions[k]?.id);
              const sections = groups[s.groupId].sections;
              if (sections) {
                sections.push(s as never);
              }
            });

          const payload = {
            ...base,
            groups: Object.values(groups)
              .sort((a, b) => a.partOrder - b.partOrder)
              .map(gr => ({
                ...gr,
                partOrder: gr.partOrder,
                questions: Object.values(questions)
                  .filter(q => q.groupId === gr.id)
                  .sort((a, b) => a.partOrder - b.partOrder),
              }))
              .filter(gr => !!gr.questions.length),
          };

          if (isInQuestionnaireContext) {
            const consolidations = [];
            for (const q of Object.values(questions)) {
              const existingQuestion = currentQuestions.find(c => c.id === q.id);
              const page = Object.values(groups).find(g => g.id === q.groupId);
              if (!existingQuestion) {
                consolidations.push({
                  question: q,
                  page: page?.partOrder || 0,
                  pageTitle: page?.title || "",
                  order: (page?.partOrder || 0) * 100 + q.partOrder,
                  label: `${q.question?.label}`,
                  id: q.question?.externalId,
                  diffs: [],
                  accepted: true,
                });
              } else {
                // TODO process display rules if needed
              }
            }

            setConsolidations(consolidations as never);
            setPendingPayload(payload);
          } else {
            console.log("creating questionnaire...", base, payload);
            setMessage("Creating questionnaire...");

            createQuestionnaire({
              ...base,
            }).then(async () => {
              for (const group of payload.groups) {
                setMessage(`Updating page (P-${group.partOrder}) ${group.title}`);
                await questionnaireBulkInsert(base.id, { ...base, groups: [group] });
              }
              navigate(base.id);
            });
          }
        },
      });
    }
  };

  const updateQuestionnaire = useCallback(async () => {
    if (!pendingPayload) return;

    setMessage("inserting data questionnaire (do not reload or leave your page)...");
    setConsolidations([]);

    for (const group of pendingPayload.groups) {
      setMessage(`Updating page (P-${group.partOrder}) ${group.title}`);
      await questionnaireBulkInsert(pendingPayload.id, { ...pendingPayload, groups: [group] });
    }

    window.location.reload();
  }, [consolidations, pendingPayload]);

  if (consolidations.length) {
    return (
      <div className='fixed top-0 left-0 z-30 bg-gray-400 bg-opacity-50 w-screen h-screen overflow-y-auto flex flex-col items-center justify-center'>
        <div
          className={
            "bg-gray-600 text-xl text-white w-[90vw] py-1 text-center flex justify-between flex-wrap gap-2 px-10"
          }
        >
          <div className={"flex-1"}>Consolidate the updates to the questionnaire</div>
          <input
            value={term}
            placeholder={"Type to filter"}
            onChange={unpack(setTerm)}
            className='w-max min-w-96 border border-gray-300 rounded-md px-1 py-1 text-base text-gray-700 focus:outline-none focus:border-transparent'
          />
        </div>
        <div className={"overflow-y-auto h-[70vh] w-[90vw] bg-white"}>
          {consolidations
            .filter(
              v =>
                v.id?.toLowerCase().includes(term.toLowerCase()) || v.label.toLowerCase().includes(term.toLowerCase()),
            )
            .map(consolidation => (
              <AccordionItem
                title={
                  <div className={"flex gap-2 justify-between w-full"}>
                    <div className={"flex gap-2 items-center"}>
                      <div
                        className={classNames("p-1 w-16 py-0.5 rounded text-center text-white text-sm", {
                          "bg-green-500": !consolidation.diffs.length,
                          "bg-sky-500": consolidation.diffs.length,
                        })}
                      >
                        {consolidation.diffs.length ? "Update" : "New"}
                      </div>
                      <div className='text-gray-400 w-48 whitespace-nowrap overflow-hidden overflow-ellipsis text-left'>
                        (#{consolidation.page}) {consolidation.pageTitle} {">"}
                      </div>
                      <div className={"text-gray-700 font-bold w-20 text-left"}>{consolidation.id}</div>

                      <div className='w-[50vw] whitespace-nowrap overflow-hidden overflow-ellipsis text-left'>
                        {consolidation.label}
                      </div>
                    </div>
                    <div className={"mr-6"} key={consolidation.accepted.toString()}>
                      <ToggleAcceptDecline
                        initial={consolidation.accepted}
                        onChange={s => {
                          consolidation.accepted = s;
                          consolidation.diffs.forEach(diff => (diff.accepted = s));
                          setConsolidations([...consolidations]);
                        }}
                      />
                    </div>
                  </div>
                }
                key={consolidation.id}
              >
                <div>
                  <div className={"flex flex-col gap-1  mx-4"}>
                    {!!consolidation.diffs.length && <div className={"font-bold"}>Changes:</div>}
                    {consolidation.diffs.map(diff => (
                      <div key={diff.part} className={"w-fit border px-2 py-0.5"}>
                        <div className={"flex items-center justify-between gap-10"}>
                          <div className={"flex gap-2 items-center"}>
                            <div>{diff.message.message}</div>
                            <div className={"text-yellow-600 line-through"}>{diff.message.from}</div>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='size-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                              />
                            </svg>

                            <div className={"text-green-500"}>{diff.message.to ?? "null"}</div>
                          </div>
                          <ToggleAcceptDecline
                            key={diff.accepted.toString()}
                            disabled={!consolidation.accepted}
                            initial={diff.accepted}
                            onChange={s => {
                              diff.accepted = s;
                              if (!consolidation.diffs.some(v => v.accepted)) {
                                consolidation.accepted = false;
                              }
                              setConsolidations([...consolidations]);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={"p-4"}>
                    <AccordionItem title={"Show Question"}>
                      <div>
                        {Object.entries(consolidation.question?.question || {})
                          .filter(([_, v]) => ![null, undefined, "", []].includes(v as never))
                          .map(([k, v]) => (
                            <div key={k} className={"border px-2 py-0.5 w-full"}>
                              <div className={"flex items-center justify-between gap-10"}>
                                <div className={"flex gap-2 items-center justify-evenly w-full"}>
                                  <div className={"text-gray-700 font-bold w-48"}>{k}</div>
                                  <div className={"text-gray-400 w-full"}>{JSON.stringify(v)}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </AccordionItem>
                  </div>
                </div>
              </AccordionItem>
            ))}
        </div>
        <div className={"flex gap-4 justify-end w-[90vw] bg-gray-100 py-2 pr-6"}>
          <div
            className={"flex rounded bg-orange-300 font-bold text-white justify-center py-1 w-32 cursor-pointer"}
            onClick={() => window.location.reload()}
          >
            Cancel
          </div>
          <div
            className={"flex rounded bg-sky-500 font-bold text-white justify-center py-1 w-32 cursor-pointer"}
            onClick={updateQuestionnaire}
          >
            Save Updates
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center justify-center bg-gray-100 p-4 mb-4'>
      <div className='w-full max-w-lg flex flex-col justify-center'>
        <h2 className='text-2xl  font-semibold mb-4 text-center'>Upload CSV or JSON File</h2>
        <input
          type='file'
          accept='.csv,.json'
          onChange={handleFileChange}
          className='block w-full text-sm text-gray-500 bg-white px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          '
        />
        {message && <div className={"text-sky-600 text-center"}>{message}</div>}
      </div>
    </div>
  );
};

export default UploadCSV;
