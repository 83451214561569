import React, { useState } from "react";
import Papa from "papaparse";

type FlattenedObject = Record<string, string>;

function flattenObject(obj: any, prefix = ''): FlattenedObject[] {
  let results: FlattenedObject[] = [{}];

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        results.forEach(result => result[newKey] = '');
      } else if (typeof value[0] === 'object' && value[0] !== null) {
        // For arrays of objects, create new rows
        const newResults: FlattenedObject[] = [];
        results.forEach(existingResult => {
          value.forEach(item => {
            const flattenedItems = flattenObject(item, newKey);
            flattenedItems.forEach(flattenedItem => {
              newResults.push({ ...existingResult, ...flattenedItem });
            });
          });
        });
        results = newResults;
      } else {
        // For arrays of primitives, join with comma
        results.forEach(result => result[newKey] = value.join(','));
      }
    }
    else if (value !== null && typeof value === 'object') {
      // Recursively flatten nested objects
      const flattenedItems = flattenObject(value, newKey);
      results.forEach(result => Object.assign(result, flattenedItems[0]));
    }
    else {
      // Handle primitive values
      results.forEach(result => result[newKey] = value !== null ? String(value) : '');
    }
  }

  return results;
}

export const UtilsPage = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [entryPoint, setEntryPoint] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setError(null);
    }
  };

  const handleJsonToCsv = () => {
    if (!selectedFile) {
      setError("Please select a file first");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const fileContent = e.target?.result as string;
        let data = JSON.parse(fileContent);

        // Handle entry point if specified
        if (entryPoint) {
          const keys = entryPoint.split(".");
          for (const key of keys) {
            data = data[key];
            if (!data) {
              throw new Error(`Entry point "${entryPoint}" not found in JSON`);
            }
          }
        }

        // Ensure data is an array
        const dataArray = Array.isArray(data) ? data : [data];

       const flattenedData = dataArray.flatMap(item => {
    const results = flattenObject(item);
    return results;
  });

  // Get all unique column headers
        const headers = Array.from(
          new Set(
            flattenedData.flatMap(obj => Object.keys(obj))
          )
        );
          

        

          
      const csv = Papa.unparse({
        fields: headers,  // equivalent to columns in csv-stringify
        data: flattenedData
      });

        // Download file
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedFile.name.replace('.json', '')}-${new Date().getTime()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsText(selectedFile);
  };

  return (
    <div className="w-full flex flex-col p-4 gap-2">
      <div className="flex items-center justify-between border-b border-gray-700 pb-2">
        <h1 className="text-lg text-gray-700">Utils</h1>
      </div>

      <div className="flex flex-col gap-4">
        <div className="border rounded-lg p-4">
          <h2 className="text-lg mb-4">JSON to CSV Converter</h2>
          
          <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-600">Select JSON File</label>
            <input
              type="file"
              accept=".json"
              onChange={handleFileChange}
              className="w-full p-2 border rounded"
            />
            {selectedFile && (
              <div className="text-sm text-gray-600">
                Selected file: {selectedFile.name}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-2 mt-4">
            <label className="text-sm text-gray-600">Entry Point (optional)</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={entryPoint}
              onChange={e => setEntryPoint(e.target.value)}
              placeholder="e.g., data.items"
            />
          </div>

          {error && <div className="text-red-500 mt-2">{error}</div>}

          <button
            className="mt-4 w-48 py-1 bg-gray-700 text-white"
            onClick={handleJsonToCsv}
          >
            Convert to CSV
          </button>
        </div>
      </div>
    </div>
  );
};
