import { useBuilderNGN } from "./BuilderNGN";
import { unpack } from "../../utils/unpack";
import UploadCSV from "./UploadCSV";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import React, { useCallback, useState } from "react";
import { Modal } from "../util/Modal";
import { cb } from "../../utils/cd";
import { NoData } from "../util/Empty";
import { RemoveIcon } from "../util/ItemActions";
import { editQuestion } from "../../http/questionnaire";
import { toast } from "react-toastify";
import { sleep } from "@Savus-Inc/runtime-ngn/dist/utils/defer";
type SectionConsolidation = {
  questionId: string;
  questionExternalId: string;
  partOrder: number;
  sectionId: string;
  sections: { value: string; label: string }[];
  default: string;
  groupId: string;
  groupOrder: number;
  parents: { value: string; label: string }[];
  parentId: string | undefined;
};

export const QuestionnaireDetails = () => {
  const { item, editQuestionnaireDetails } = useBuilderNGN();
  const [showModal, setShowModal] = useState(false);
  const [sectionConsolidations, setSectionConsolidations] = useState<SectionConsolidation[]>([]);
  const [loading, setLoading] = useState(false);

  const consolidateSections = useCallback(() => {
    const consolidations: SectionConsolidation[] = [];

    for (const group of item.groups) {
      const availableSections =
        group.sections?.map(s => ({ value: s.id, label: s.title || `Section: ${s.partOrder.toString()}` })) || [];

      for (const question of group.questions) {
        if (!question.sectionId && availableSections.length) {
          const parents = group?.questions.filter(v => !v.parentId && v.id !== question?.id) || [];

          let parent = question.parentId ? group.questions.find(v => v.id === question.parentId) : undefined;

          if (!parent) {
            parent = parents[0];
          }

          consolidations.push({
            default: availableSections[0].label || "Select",
            groupId: group.id,
            groupOrder: group.partOrder,
            partOrder: question.partOrder,
            questionExternalId: question.question?.externalId || "TMP",
            questionId: question.id,
            sectionId:
              parent?.question?.externalId === "Q-37"
                ? availableSections[1].value || ""
                : availableSections[0].value || "",
            sections: availableSections,
            parentId: parent?.id,
            parents: parents.map(v => ({ value: v.id, label: v.question?.externalId || "" })) || [],
          });
        }
      }
    }

    setSectionConsolidations(consolidations);
    setShowModal(true);
  }, [item]);

  const removeConsolidation = (id: string) => {
    setSectionConsolidations(sectionConsolidations.filter(sc => sc.questionId !== id));
  };

  const saveConsolidations = async () => {
    setLoading(true);

    for (const sc of sectionConsolidations) {
      await editQuestion(item.id, sc.groupId, sc.questionId, {
        partOrder: sc.partOrder,
        sectionId: sc.sectionId,
        parentId: sc.parentId,
      }).catch(console.error);
    }

    toast.success("Consolidations saved");
    await sleep(1500);
    window.location.reload();
  };

  const selectNewSection = (id: string) => (sectionId: string) => {
    setSectionConsolidations(sectionConsolidations.map(sc => (sc.questionId === id ? { ...sc, sectionId } : sc)));
  };

  const selectNewParent = (id: string) => (parentId: string) => {
    setSectionConsolidations(sectionConsolidations.map(sc => (sc.questionId === id ? { ...sc, parentId } : sc)));
  };

  return (
    <div className='flex flex-col gap-4 bg-white ml-1 rounded min-h-[88vh] p-2 '>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Name:
        </label>
        <input
          type='text'
          id='name'
          name='name'
          onBlur={unpack(editQuestionnaireDetails("name"))}
          defaultValue={item?.name || ""}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
        />
      </div>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Title:
        </label>
        <input
          type='text'
          id='title'
          name='title'
          defaultValue={item?.title || ""}
          onBlur={unpack(editQuestionnaireDetails("title"))}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
        />
      </div>

      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Sub Text:
        </label>
        <textarea
          id='sub'
          name='sub'
          onBlur={unpack(editQuestionnaireDetails("subText"))}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
          defaultValue={item?.subText || ""}
        />
      </div>
      <div className={"flex gap-2 items-center"}>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Consolidate Question Sections:
        </label>
        <button className={"w-32 py-1 text-sky-500 rounded border border-sky-500"} onClick={consolidateSections}>
          Consolidate
        </button>
      </div>
      <UploadCSV />

      <Modal isOpen={showModal} onClose={cb(setShowModal, false)}>
        <div className={"w-full mt-10 mb-2 pt-4 border-t"}>
          {sectionConsolidations.length ? (
            <div className={"flex flex-col gap-2 max-h-[50vh] overflow-y-auto"}>
              {sectionConsolidations.map(sc => (
                <div key={sc.questionId} className={"flex gap-2 items-center shadow-sky-500 px-2 py-0.5 border-b"}>
                  <div className={"w-32"}>{sc.questionExternalId}</div>

                  <div className={"w-64 text-center bg-gray-100"}>Page: {sc.groupOrder}</div>
                  {!!sc.parents.length ? (
                    <SelectAutocomplete
                      suggestions={sc.parents}
                      onSelect={selectNewParent(sc.questionId)}
                      value={sc.parents.find(v => v.value === sc.parentId)?.label || "Select Parent"}
                      placeholder={"Select Parent"}
                    />
                  ) : (
                    <div className={"w-64"} />
                  )}
                  <SelectAutocomplete
                    suggestions={sc.sections}
                    onSelect={selectNewSection(sc.questionId)}
                    value={sc.sections.find(v => v.value === sc.sectionId)?.label || sc.default}
                    placeholder={"Select Section"}
                  />
                  <div className={"text-red-400 cursor-pointer"} onClick={cb(removeConsolidation, sc.questionId)}>
                    <RemoveIcon />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoData message={"No Questions without a section found"} />
          )}
        </div>
        <div className={"flex justify-end gap-2 p-2 pt-5 border-t border-gray-300"}>
          <button className={"px-4 py-1 rounded w-32 bg-gray-200 text-gray-700"} onClick={cb(setShowModal, false)}>
            Cancel
          </button>
          <button
            disabled={loading}
            className={"disabled:bg-gray-200 px-4 py-1 w-32 rounded bg-sky-500 text-white"}
            onClick={saveConsolidations}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  );
};
